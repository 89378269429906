import "./verification_style.scss";

const VerificationPage = () => {
    return (
        <>
            <div className="verification-body">
                <h4>
                    Awaiting Verification....
                </h4>
            </div>
        </>
    );
}

export default VerificationPage;